
import { defineComponent } from 'vue'
// import Button from '@/components/Button.vue'
import HoldOn from '@/provider/HoldOn.vue'
import TheAdjustmentItem from '@/pages/leverage/components/TheAdjustmentItem.vue'
import { openDialog, showAlert } from '@/components/popup/popup'
import LeverageConfirmDailog from '@/pages/leverage/components/LeverageConfirmDailog.vue'
import { readLever } from '@/pages/leverage/leverage.api'
import Pic from '@/components/Pic.vue'
import { translate } from '@/i18n'
import {
  listData,
  submitActive,
  options,
  activeIndex,
  currentChoose,
} from '@/pages/leverage/leverage'

export default defineComponent({
  name: 'TheAdjustment',
  components: {
    TheAdjustmentItem,
    // Button,
    HoldOn,
    Pic,
  },
  setup () {
    const itemChange = (index: number) => {
      listData.forEach(item => {
        item.active = false
      })
      listData[index].active = true
      activeIndex.value = index
      submitActive.value = !listData[index].choosed
    }

    const setChoosedItem = (index: number) => {
      listData.forEach(item => {
        item.active = false
        item.choosed = false
      })
      listData[index].active = true
      listData[index].choosed = true
      currentChoose.value = index
      submitActive.value = !listData[index].choosed
    }

    readLever().then(res => {
      res.forEach((item, index) => {
        if (item.selected) {
          setChoosedItem(index)
          options.value = res
        }
      })
    })

    const confirmSubmit = () => {
      if (!submitActive.value) {
        return false
      }
      openDialog(LeverageConfirmDailog, {
        activeIndex: activeIndex.value,
        newlever: translate(listData[activeIndex.value].titleLable),
        currentlever: translate(listData[currentChoose.value].titleLable),
        onSuccess: () => {
          setChoosedItem(activeIndex.value)
          showAlert(translate('toast_13'))
        },
      })
    }
    return {
      listData,
      submitActive,
      options,
      itemChange,
      confirmSubmit,
    }
  },
})
