import { reactive, shallowRef } from 'vue'
import { openDialog, showAlert } from '@/components/popup/popup'
import LeverageConfirmDailog from '@/pages/leverage/components/LeverageConfirmDailog.vue'
import { translate } from '@/i18n'

export const listData = reactive([{
  index: 0,
  title: 'Low',
  titleLable: 'leverage_5',
  subTitleLable: 'leverage_6',
  active: false,
  choosed: false,
  subTitle: 'Friendly to newbies, Good for Investors with low risk appetite',
  assets: [{
    code: 'USDJPY',
    leverage: '100:1',
  }, {
    code: 'Gold',
    leverage: '48:1',
  }, {
    code: 'Bitcoin',
    leverage: '37:1',
  }, {
    code: 'Facebook',
    leverage: '10:1',
  }],
}, {
  index: 1,
  title: 'Moderate',
  titleLable: 'leverage_7',
  subTitleLable: 'leverage_8',
  active: false,
  choosed: false,
  subTitle: 'Friendly to newbies, Good for Investors with low risk appetite',
  assets: [{
    code: 'USDJPY',
    leverage: '200:1',
  }, {
    code: 'Gold',
    leverage: '96:1',
  }, {
    code: 'Bitcoin',
    leverage: '74:1',
  }, {
    code: 'Facebook',
    leverage: '20:1',
  }],
}, {
  index: 2,
  title: 'High',
  titleLable: 'leverage_9',
  subTitleLable: 'leverage_10',
  active: false,
  choosed: false,
  subTitle: 'Friendly to newbies, Good for Investors with low risk appetite',
  assets: [{
    code: 'USDJPY',
    leverage: '400:1',
  }, {
    code: 'Gold',
    leverage: '192:1',
  }, {
    code: 'Bitcoin',
    leverage: '148:1',
  }, {
    code: 'Facebook',
    leverage: '40:1',
  }],
}])
export const submitActive = shallowRef(false)
export const options = shallowRef()
export const activeIndex = shallowRef(0)
export const currentChoose = shallowRef(0)

const setChoosedItem = (index: number) => {
  listData.forEach(item => {
    item.active = false
    item.choosed = false
  })
  listData[index].active = true
  listData[index].choosed = true
  currentChoose.value = index
  submitActive.value = !listData[index].choosed
}

export const confirmSubmit = () => {
  if (!submitActive.value) {
    return false
  }
  openDialog(LeverageConfirmDailog, {
    activeIndex: activeIndex.value,
    newlever: translate(listData[activeIndex.value].titleLable),
    currentlever: translate(listData[currentChoose.value].titleLable),
    onSuccess: () => {
      setChoosedItem(activeIndex.value)
      showAlert(translate('toast_13'))
    },
  })
}
