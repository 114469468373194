
import DialogTemplate from '@/views/DialogTemplate.vue'
import Button from '@/components/Button.vue'
import { defineComponent } from 'vue'
import { useSetLever } from '@/pages/leverage/leverage.api'

export default defineComponent({
  name: 'LeverageConfirmDailog',
  components: { DialogTemplate, Button },
  props: {
    activeIndex: {
      type: Number,
      required: true,
    },
    newlever: String,
    currentlever: String,
  },
  emits: ['success'],
  setup (props, ctx) {
    const { request, progress } = useSetLever()
    const submit = () => {
      request({
        type: props.activeIndex + 1,
      }).then(() => {
        ctx.emit('success')
      })
    }
    return {
      submit,
      progress,
    }
  },
})
