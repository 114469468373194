
import { defineComponent } from 'vue'
import Pic from '@/components/Pic.vue'

export default defineComponent({
  name: 'TheLeverLearn',
  components: {
    Pic,
  },
  setup () {
    return {
      tableData: [{
        title: 'Margin',
        low: '$10',
        moderate: '$10',
        high: '$10',
      }, {
        title: 'Volume',
        low: '0.25 oz',
        moderate: '0.5 oz',
        high: '1 oz',
      }, {
        title: 'Buying power',
        low: '$465',
        moderate: '$930',
        high: '$1860',
      }, {
        title: 'Leverage',
        low: '46.5:1',
        moderate: '93:1',
        high: '186:1',
      }, {
        title: 'Profit per pip',
        low: '$0.025',
        moderate: '$0.05',
        high: '$0.1',
      }, {
        title: 'Profit',
        low: '$10',
        moderate: '$20',
        high: '$40',
      }, {
        title: 'Profit%',
        low: '100%',
        moderate: '200%',
        high: '400%',
      }],
    }
  },
})
