
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'componentName',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  emits: ['change'],
  setup (props, ctx) {
    const handleChoose = () => {
      if (props.item.active) {
        return false
      } else {
        ctx.emit('change', props.item.index)
      }
    }
    return {
      handleChoose,
    }
  },
})
